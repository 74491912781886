/*
 * @Author: zhongxue
 * @Date: 2020-09-06 21:02:00
 * @LastEditors  : zhongxue
 * @LastEditTime : 2020-09-07 10:00:00
 */
import React from 'react';
import { Switch, Route, Redirect } from 'dva/router';
import PropTypes from 'prop-types';

function RouterView(props) {
  const {
    routes
  } = props;
  if (!routes || routes.length === 0) return null;
  return <Switch data-inspector-line="16" data-inspector-column="4" data-inspector-relative-path="src\\router\\router-view.jsx">
      {routes.map((item, key) => {
      const {
        path
      } = item;
      if (item.from) return <Redirect data-inspector-line="20" data-inspector-column="31" data-inspector-relative-path="src\\router\\router-view.jsx" key={key} from={item.from} to={item.to} />;
      return <Route data-inspector-line="22" data-inspector-column="12" data-inspector-relative-path="src\\router\\router-view.jsx" key={key} path={path} exact={item.exact} render={renderProps => {
        const Component = item.component;

        if (!Component) {
          return null;
        }

        return <Component data-inspector-line="31" data-inspector-column="23" data-inspector-relative-path="src\\router\\router-view.jsx" {...renderProps} {...item} />;
      }} />;
    })}
    </Switch>;
}

RouterView.defaultProps = {
  routes: []
};
RouterView.propTypes = {
  routes: PropTypes.array
};
export default RouterView;